<template>
  <div>
    <FilterAffiliazioniEconomato
      @getEconomatoList="searchAffiliazioniList"
      @resetFilters="resetFilters"
    />
    <TableAffiliazioniEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterAffiliazioniEconomato from "@/components/components-fit/gestione/economato/FiltriAffiliazioniEconomato.vue";
import TableAffiliazioniEconomato from "@/components/components-fit/gestione/economato/TableAffiliazioniEconomato.vue";

export default defineComponent({
  name: "affiliazioni-economato",
  components: {
    FilterAffiliazioniEconomato,
    TableAffiliazioniEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleAffiliazioni
    );
    const idSocieta = computed(() => store.getters.idSocietaAffiliazioni);

    const startDateEmissione = computed(
      () => store.getters.dataEmissioneDaAffiliazioni
    );
    const endDateEmissione = computed(
      () => store.getters.dataEmissioneAAffiliazioni
    );
    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaAffiliazioni
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneAAffiliazioni
    );
    const approvato = computed(() => store.getters.approvatoAffiliazioni);
    const meseApprovazione = computed(
      () => store.getters.meseApprovazioneAffiliazioni
    );

    const rowsToSkip = computed(() => store.getters.rowsToSkipAffiliazioni);
    const fetchRows = computed(() => store.getters.fetchRowsAffiliazioni);
    const sortColumn = computed(() => store.getters.sortColumnAffiliazioni);
    const sortOrder = computed(() => store.getters.sortOrderAffiliazioni);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_Comitato: idComitatoRegionale.value,
        id_Societa: idSocieta.value,
        data_Ins_Da: startDateEmissione.value,
        data_Ins_A: endDateEmissione.value,
        data_Appr_Da: startDateApprovazione.value,
        data_Appr_A: endDateApprovazione.value,
        approvato: approvato.value,
        mese: meseApprovazione.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_AFFILIAZIONI_LIST,
        itemName: "economato_affiliazioni_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersEconomato");
      getEconomatoList();
    };
    getEconomatoList();

    const searchAffiliazioniList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAffiliazioni");
      getEconomatoList();
    };
    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_affiliazioni_list")
    );

    return {
      getEconomatoList,
      resetFilters,
      searchAffiliazioniList,
    };
  },
});
</script>
