<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="text-start col-6">
        <button
          type="button"
          class="btn btn-light text-gray-700 fs-6"
          data-bs-toggle="modal"
          data-bs-target="#modal_esporta_affiliazioni_economato"
        >
          <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
          filtrata
        </button>
      </div>
      <ModalEsportaAffiliazioniEconomato />
      <div class="col-6">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
    <br />
    <div class="mx-auto my-auto text-center" v-if="!loaded">
      <div class="spinner-border text-gray-600" role="status"></div>
      <span class="text-gray-600 ps-3 fs-2">Loading</span>
    </div>
    <div
      v-else-if="
        economato_affiliazioni_list.length == 0 &&
        loaded &&
        (status == 200 || status == 204)
      "
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Nessun risultato.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
        @click.prevent="$emit('resetFilters')"
      >
        <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
      ></span>
    </div>
    <div
      v-else-if="status != 200 && status != 204"
      class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
    >
      <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
      ></span>
    </div>
    <div v-else>
      <Datatable
        :table-header="tableHeader"
        :table-data="economato_affiliazioni_list"
        :total="record"
        :current-page="currentPage"
        :rows-per-page="fetchRows"
        :sortLabel="sortColumn"
        :order="sortOrder"
        @sort="setSortOrderColumn"
        @items-per-page-change="setFetchRowsAffiliazioni"
        @current-change="setCurrentPageAffiliazioni"
      >
        <template v-slot:cell-numero="{ row: data }"
          ><div class="">
            {{ data.numero }}
          </div>
        </template>
        <template v-slot:cell-causale="{ row: data }"
          ><div class="">
            {{ data.causale }}
          </div>
        </template>
        <template v-slot:cell-soggetto="{ row: data }"
          ><div class="tab-long">
            {{ data.soggetto }}
          </div>
        </template>
        <template v-slot:cell-descrizione="{ row: data }"
          ><div class="tab-long">
            {{ data.descrizione }}
          </div>
        </template>
        <template v-slot:cell-importo="{ row: data }"
          ><div class="text-end">
            {{ data.importo.toFixed(2) }}
          </div>
        </template>
        <template v-slot:cell-data_inserimento="{ row: data }"
          ><div class="text-center">
            {{ data.data_inserimento }}
          </div>
        </template>
        <template v-slot:cell-data_approvazione="{ row: data }"
          ><div class="text-center">
            {{ data.data_approvazione }}
          </div>
        </template>
        <template v-slot:cell-quote="{ row: data }"
          ><div class="">
            {{ data.quote }}
          </div>
        </template>
        <template v-slot:cell-options="{ row: data }">
          <div
            v-if="!readOnly"
            class=""
            v-show="
              (!data.data_approvazione && data.approvabile) ||
              (data.data_approvazione && data.ratificabile)
            "
          >
            <button
              class="btn btn-sm dropdown p-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
            </button>
            <ul class="dropdown-menu">
              <a href="#" @click="apprMovAffiliazione(data.id_movimento)">
                <li
                  role="presentation"
                  class="navi-item"
                  v-if="
                    !data.data_approvazione && data.approvabile && !readOnly
                  "
                >
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Approva </span>
                    </div>
                  </div>
                </li></a
              >
              <a
                href="#"
                @click="ratificaAffiliazione(data.id_societa)"
                v-if="data.ratificabile && !readOnly"
              >
                <li role="presentation" class="navi-item">
                  <div class="b-dropdown-text text-start py-2 px-4">
                    <div class="navi-link">
                      <i class="bi bi-check text-dark fs-3"></i>
                      <span class="text-dark ps-1 fs-7"> Ratifica </span>
                    </div>
                  </div>
                </li></a
              >
              <!-- <li
                role="presentation"
                class="navi-item"
                v-show="data.cancellabile"
              >
                <div class="b-dropdown-text text-start py-2 px-4">
                  <a
                    href="#"
                    class="navi-link"
                    @click="delMovAffiliazione(data.id_movimento)"
                  >
                    <i class="bi bi-trash text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Elimina</span>
                  </a>
                </div>
              </li> -->
            </ul>
          </div></template
        >
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed } from "vue";
import { useStore } from "vuex";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import {
  approvaMovAffiliazione,
  deleteMovAffiliazione,
  ratificaMovAffiliazione,
} from "@/requests/economatoAffiliazioniRequests";

import ModalEsportaAffiliazioniEconomato from "./ModalEsportaAffiliazioniEconomato";

import Loading from "vue3-loading-overlay";
import isEnabled from "@/composables/isEnabled.js";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default {
  name: "TableAffiliazioniEconomato",
  components: { Datatable, Loading, ModalEsportaAffiliazioniEconomato },
  emits: ["getEconomatoList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const tableHeader = ref([
      {
        name: "Numero",
        key: "numero",
      },
      {
        name: "Causale",
        key: "causale",
      },
      {
        name: "Soggetto",
        key: "soggetto",
      },
      {
        name: "Descrizione",
        key: "descrizione",
      },
      {
        name: "Importo",
        key: "importo",
        align: "end",
      },
      {
        name: "Data inserimento",
        key: "data_inserimento",
        align: "center",
      },
      {
        name: "Data approvazione",
        key: "data_approvazione",
        align: "center",
      },
      {
        name: "Quote",
        key: "quote",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);
    const store = useStore();

    const setFetchRowsAffiliazioni = (e) => {
      store.commit("setFetchRowsAffiliazioni", e);
      emit("getEconomatoList");
    };
    const setCurrentPageAffiliazioni = (page) => {
      store.commit("setCurrentPageAffiliazioni", page);
      emit("getEconomatoList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnAffiliazioni", columnName);
      store.commit("setSortOrderAffiliazioni", order);
      emit("getEconomatoList");
    };

    //richiesta Api
    // const affiliazione = ref({});

    // const idAffiliazione = ref(null);
    // const setIdAffiliazione = async (id) => {
    //   idAffiliazione.value = id;
    // };

    const isLoading = ref(false);

    const apprMovAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il movimento verrà approvato.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Approva",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await approvaMovAffiliazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Movimento approvato correttamente");
              emit("getEconomatoList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const stagioneSelected = computed(() => store.getters.stagioneSelected);

    const ratificaAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "L'affiliazione verrà ratificata.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Ratifica",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await ratificaMovAffiliazione(id, stagioneSelected.value).then(
            (res) => {
              if (res.status == 200) {
                alertSuccess("Affiliazione ratificata correttamente");
                emit("getEconomatoList");
              } else {
                alertFailed(
                  res.data.message
                    ? res.data.message
                    : "Attenzione! Si è verificato un errore. Riprovare più tardi"
                );
              }
              isLoading.value = false;
            }
          );
        }
      });
    };

    const delMovAffiliazione = (id) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il movimento verrà eliminato definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteMovAffiliazione(id).then((res) => {
            if (res.status == 200) {
              alertSuccess("Movimento eliminato correttamente");
              emit("getEconomatoList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const esportaLista = () => {
      Swal.fire({
        html: "Funzionalità presto disponibile",
        icon: "warning",
        buttonsStyling: false,
        confirmButtonText: "Chiudi",
        customClass: {
          confirmButton: "btn fw-bold btn-light-warning",
        },
      });
    };

    return {
      delMovAffiliazione,
      apprMovAffiliazione,
      ratificaAffiliazione,
      isLoading,
      tableHeader,
      // affiliazione,
      // idAffiliazione,
      // setIdAffiliazione,
      setFetchRowsAffiliazioni,
      setCurrentPageAffiliazioni,
      setSortOrderColumn,
      isEnabled,

      economato_affiliazioni_list: computed(() =>
        store.getters.getStateFromName("resultseconomato_affiliazioni_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_affiliazioni_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordeconomato_affiliazioni_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statuseconomato_affiliazioni_list")
      ),
      currentPage: computed(() => store.getters.currentPageAffiliazioni),
      rowsToSkip: computed(() => store.getters.rowsToSkipAffiliazioni),
      fetchRows: computed(() => store.getters.fetchRowsAffiliazioni),
      sortColumn: computed(() => store.getters.sortColumnAffiliazioni),
      sortOrder: computed(() => store.getters.sortOrderAffiliazioni),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),
      esportaLista,
    };
  },
};
</script>

<style scoped></style>
